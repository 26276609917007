import { useMemo } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { TypePolicy } from "@apollo/client/cache/inmemory/policies";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { useAuth0 } from "@auth0/auth0-react";
import { TABLES_HAVING_PK_OTHER_THAN_ID } from "constants/tablesHavingPkOtherThanId";

export default function useApolloClient() {
  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const typePolicies = Object.entries(TABLES_HAVING_PK_OTHER_THAN_ID).reduce(
      (policies, [key, [value]]) => {
        const type: Record<string, TypePolicy> = {
          [key]: {
            keyFields: [value],
          },
        };
        return { ...policies, ...type };
      },
      {}
    );

    const httpLink = new BatchHttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT,
    });

    const authLink = setContext(async () => {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({ typePolicies }),
    });

    return client;
  }, [getAccessTokenSilently]);

  return { client };
}
