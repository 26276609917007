export const ORDER_TYPE_FIELDS = `
name
category
position
form_type_id
is_deleted
code
`;

export const SESSION_TYPE_FIELDS = `
code
is_deleted
description
program_id
title
is_kanban_flow
`;

export const SCHOOL_YEAR_FIELDS = `
organization_id
schoolyear_id
first_day_of_school
last_day_of_school
schoolweek_days_nbr
created_ts
updated_ts
organization_x_schoolyear_id
`;

export const TESTING_LOCATIONS_FIELDS = `
location_id
testing_location_id
from_dt
to_dt
created_ts
updated_ts
location_x_testing_location_id
`;

export const DAYS_OFF_FIELDS = `
days_off_id
organization_id
days_off_nm
from_dt
to_dt
schoolyear_id
created_ts
updated_ts
`;

export const EVENT_TYPES_FIELDS = `
code
title`;
