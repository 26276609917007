import { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { DataProvider } from "react-admin";
import { customDataProvider } from "../providers";
import dynamic from "next/dynamic";
import useSentry from "hooks/useSentry";
import useApolloClient from "hooks/useApolloClient";
import useAuthProvider from "hooks/useAuthProvider";

const Dashboard = dynamic(() => import("components/Dashboard"), {
  ssr: false,
});

function Home() {
  useSentry();
  const { client } = useApolloClient();
  const { authProvider } = useAuthProvider();
  const [dataProvider, setDataProvider] = useState<DataProvider>();

  useEffect(() => {
    async function buildCustomDataProvider() {
      const hasuraDataProvider = await customDataProvider(client);
      setDataProvider(hasuraDataProvider);
    }

    buildCustomDataProvider();
  }, [client]);

  return <Dashboard dataProvider={dataProvider} authProvider={authProvider} />;
}

export default withAuthenticationRequired(Home);
