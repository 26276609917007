import type { ApolloClient } from "@apollo/client";
import buildHasuraProvider from "ra-data-hasura";
import { TABLES_HAVING_PK_OTHER_THAN_ID } from "../constants/tablesHavingPkOtherThanId";
import { checkIfTablePkIsOtherThanId } from "../utils/checkIfTablePkIsOtherThanId";
import {
  customGetList,
  customGetOne,
  customGetMany,
  customGetManyReference,
  customCreate,
  customUpdate,
  customUpdateMany,
  customDelete,
  customDeleteMany,
} from "./utils";

export const customDataProvider = async (client: ApolloClient<unknown>) => {
  const hasuraDataProvider = await buildHasuraProvider({ client });
  return {
    getList: (resource, params) =>
      customGetList(
        hasuraDataProvider,
        resource,
        params,
        TABLES_HAVING_PK_OTHER_THAN_ID[resource]
      ),
    getOne: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customGetOne(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.getOne(resource, params);
    },
    getMany: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customGetMany(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.getMany(resource, params);
    },
    getManyReference: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customGetManyReference(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.getManyReference(resource, params);
    },
    update: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customUpdate(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.update(resource, params);
    },
    updateMany: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customUpdateMany(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.updateMany(resource, params);
    },
    create: (resource, params) => {
      if (checkIfTablePkIsOtherThanId(resource))
        return customCreate(
          client,
          resource,
          params,
          TABLES_HAVING_PK_OTHER_THAN_ID[resource]
        );
      else return hasuraDataProvider.create(resource, params);
    },
    delete: (resource, params) =>
      customDelete(
        resource,
        params,
        client,
        TABLES_HAVING_PK_OTHER_THAN_ID[resource]
      ),
    deleteMany: (resource, params) =>
      customDeleteMany(
        resource,
        params,
        client,
        TABLES_HAVING_PK_OTHER_THAN_ID[resource]
      ),
  };
};
