import type { AuthProvider } from "react-admin";
import { useAuth0 } from "@auth0/auth0-react";

export default function useAuthProvider(): { authProvider: AuthProvider } {
  const { user } = useAuth0();

  return {
    authProvider: {
      login: () => Promise.resolve(),
      logout: () => Promise.resolve(),
      checkError: () => Promise.resolve(),
      checkAuth: () => Promise.resolve(),
      getPermissions: () => Promise.resolve(),
      getIdentity: () =>
        Promise.resolve({
          id: user.sub,
          fullName: user.nickname,
          avatar: user.picture,
        }),
    },
  };
}
