import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

export default function useSentry() {
  const { user } = useAuth0();

  useEffect(() => {
    if (user) Sentry.setUser({ id: user.sub, email: user.email });
  }, [user]);
}
