import { gql } from "@apollo/client";
import type {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import type { ApolloClient } from "@apollo/client";
import { checkIfTablePkIsOtherThanId } from "../utils/checkIfTablePkIsOtherThanId";
import {
  ORDER_TYPE_FIELDS,
  SESSION_TYPE_FIELDS,
  SCHOOL_YEAR_FIELDS,
  DAYS_OFF_FIELDS,
  TESTING_LOCATIONS_FIELDS,
  EVENT_TYPES_FIELDS,
} from "./constants";

const fields = {
  order_type: ORDER_TYPE_FIELDS,
  session_type: SESSION_TYPE_FIELDS,
  organizations_district_days_off: DAYS_OFF_FIELDS,
  organizations_testing_locations: TESTING_LOCATIONS_FIELDS,
  organizations_school_years: SCHOOL_YEAR_FIELDS,
  event_types: EVENT_TYPES_FIELDS,
};

type CustomPK = [string, string?];

export const customGetList = async (
  hasuraApolloClient: DataProvider,
  resource: string,
  params: GetListParams,
  [pk]: CustomPK = ["id"]
) => {
  if (checkIfTablePkIsOtherThanId(resource) && params.sort.field == "id")
    params.sort.field = `${pk}`;

  let { data, ...metadata } = await hasuraApolloClient.getList(
    resource,
    params
  );
  if (checkIfTablePkIsOtherThanId(resource)) {
    data = data.map((val) => ({
      ...val,
      id: val[pk],
    }));
  }
  return {
    data: data as any[],
    ...metadata,
  };
};

export const customGetOne = (
  client: ApolloClient<unknown>,
  resource: string,
  params: GetOneParams,
  [pk, type]: CustomPK = ["id", "String!"]
) =>
  client
    .query({
      query: gql`
    query ($id: ${type}) {
        ${resource}_by_pk(${pk}: $id) {
            ${fields[resource]}
        }
    }`,
      variables: {
        id: params.id,
      },
    })
    .then((result) => ({
      data: {
        ...result.data[`${resource}_by_pk`],
        id: result.data[`${resource}_by_pk`][pk],
      },
    }));

export const customGetMany = (
  client: ApolloClient<unknown>,
  resource: string,
  params: GetManyParams,
  [pk]: CustomPK = ["id"]
) =>
  client
    .query({
      query: gql`
  query ($where: ${resource}_bool_exp) {
      ${resource}(where: $where) {
          ${fields[resource]}
      }
  }`,
      variables: {
        where: {
          [pk]: { _in: params.ids },
        },
      },
    })
    .then((result) => {
      const getManyData = result.data[resource].map((resource) => ({
        ...resource,
        id: resource[pk],
      }));
      return { data: getManyData };
    });

export const customGetManyReference = (
  client: ApolloClient<unknown>,
  resource: string,
  params: GetManyReferenceParams,
  [pk]: CustomPK = ["id"]
) => {
  const { field, order } = params.sort;
  const { page, perPage } = params.pagination;
  return client
    .query({
      query: gql`
                    query ($limit: Int, $offset: Int, $order_by: [${resource}_order_by!], $where: ${resource}_bool_exp) {
                        ${resource}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
                            ${fields[resource]}
                        }
                        ${resource}_aggregate(where: $where) {
                            aggregate {
                                count
                            }
                        }
                    }`,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(params.filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: params.filter[key] },
          }),
          { [params.target]: { _eq: params.id } }
        ),
      },
    })
    .then((result) => {
      const getManyReferenceData = result.data[resource].map((resource) => ({
        ...resource,
        id: resource[pk],
      }));
      return {
        data: getManyReferenceData,
        total: result.data[`${resource}_aggregate`].aggregate.count,
      };
    });
};

export const customCreate = (
  client: ApolloClient<unknown>,
  resource: string,
  params: CreateParams,
  [pk]: CustomPK = ["id"]
) => {
  const { __typename, id, ...rest } = params.data;
  return client
    .mutate({
      mutation: gql`
            mutation ($data: ${resource}_insert_input!) {
                insert_${resource}_one(object: $data) {
                    ${fields[resource]}
                }
            }`,
      variables: {
        data: rest,
      },
    })
    .then((result) => {
      const createData = result.data[`insert_${resource}_one`];
      createData["id"] = createData[`${pk}`];
      return {
        data: createData,
      };
    });
};

export const customUpdate = (
  client: ApolloClient<unknown>,
  resource: string,
  params: UpdateParams,
  [pk, type]: CustomPK = ["id", "String!"]
) => {
  const { __typename, id, ...rest } = params.data;
  return client
    .mutate({
      mutation: gql`
          mutation ($${pk}: ${type}, $data: ${resource}_set_input!) {
              update_${resource}_by_pk(pk_columns: { ${pk}: $${pk} }, _set: $data) {
                  ${fields[resource]}
              }
          }`,
      variables: {
        [pk]: params.id,
        data: rest,
      },
    })
    .then((result) => {
      const updatedData = result.data[`update_${resource}_by_pk`];
      updatedData["id"] = updatedData[`${pk}`];
      return {
        data: updatedData,
      };
    });
};

export const customUpdateMany = (
  client: ApolloClient<unknown>,
  resource: string,
  params: UpdateManyParams,
  [pk]: CustomPK = ["id"]
) =>
  client
    .mutate({
      mutation: gql`
            mutation ($where: ${resource}_bool_exp!, $data: ${resource}_set_input!) {
                update_${resource}(where: $where, _set: $data) {
                    affected_rows
                }
            }`,
      variables: {
        where: {
          [pk]: { _in: params.ids },
        },
      },
    })
    .then((result) => ({
      data: params.ids,
    }));

export const customDelete = (
  resource: string,
  params: DeleteParams,
  client: ApolloClient<unknown>,
  [pk, type]: CustomPK = ["id", "String!"]
) =>
  client
    .mutate({
      mutation: gql`
                      mutation ($${pk}: ${type}) {
                          update_${resource}_by_pk(pk_columns: {${pk}: $${pk}}, _set: {is_deleted: true}) {
                            ${pk}
                          }
                      }`,
      variables: {
        [pk]: params.id,
      },
    })
    .then((result) => ({
      data: result.data[`delete_${resource}_by_pk`],
    }));

export const customDeleteMany = (
  resource: string,
  params: DeleteManyParams,
  client: ApolloClient<unknown>,
  [pk]: CustomPK = ["id"]
) =>
  client
    .mutate({
      mutation: gql`
                      mutation ($where: ${resource}_bool_exp!) {
                          update_${resource}(where: $where, _set: {is_deleted: true}) {
                              affected_rows
                          }
                      }`,
      variables: {
        where: {
          [pk]: { _in: params.ids },
        },
      },
    })
    .then((result) => ({
      data: params.ids,
    }));
