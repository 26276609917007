export const TABLES_HAVING_PK_OTHER_THAN_ID: Record<string, [string, string?]> =
  {
    order_type: ["code"],
    session_type: ["code"],
    organizations_district_days_off: ["days_off_id", "bigint!"],
    organizations_testing_locations: [
      "location_x_testing_location_id",
      "bigint!",
    ],
    organizations_school_years: ["organization_x_schoolyear_id", "bigint!"],
    event_types: ["code"],
  };
